import { FC } from 'react';
import {
  AutocompleteArrayInput,
  BooleanInput,
  Edit,
  EditProps,
  FormDataConsumer,
  Identifier,
  ImageField,
  ImageInput,
  RaRecord,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SingleFieldList,
  TextInput,
} from 'react-admin';
import { Grid } from '@mui/material';

import SimpleForm from 'components/SimpleForm';
import AppChipField from '../apps/AppChipField';
import { AppOptionRenderer } from '../units/components/UnitEditApps/AppOptionRenderer';

export const CollectionEdit: FC<EditProps> = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <Grid container direction="column" xs={12} lg={6} xl={4} sx={{ mb: 4 }}>
        <ImageInput label="custom.labels.icon" source="icon">
          <ImageField source="src" />
        </ImageInput>
        <TextInput source="name" />
        <ReferenceInput source="flavour_id" reference="flavours">
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceArrayInput source="designation_ids" reference="designations">
          <SelectArrayInput source="name" />
        </ReferenceArrayInput>
        <BooleanInput source="available_as_subscription" />
        <BooleanInput
          source="unlockable"
          helperText="Availability as unlockable collection from next release forward."
        />
      </Grid>
      <ReferenceArrayInput source="app_ids" reference="apps">
        <AutocompleteArrayInput
          source="name"
          fullWidth
          optionText={<AppOptionRenderer />}
          inputText={(option) => option.translated_name}
          matchSuggestion={(filter, choice) => choice.translated_name.toLowerCase().includes(filter.toLowerCase())}
        />
      </ReferenceArrayInput>
      <FormDataConsumer>
        {({ formData, ...rest }: { formData: RaRecord<Identifier> }) => (
          <ReferenceArrayField source="app_ids" reference="apps" record={formData}>
            <SingleFieldList linkType={false}>
              <AppChipField formData={formData} />
            </SingleFieldList>
          </ReferenceArrayField>
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);
