import * as React from 'react';
import { Avatar, Tooltip } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { grey } from '@mui/material/colors';
import theme from '../../../layout/theme';

export default function SurfaceIcon(props: any) {
  const record = useRecordContext(props);
  let bgcolor: any;
  let color: any;
  switch (record.name) {
    case 'wall':
      bgcolor = grey;
      break;
    case 'ceiling':
      // @ts-ignore
      bgcolor = theme.palette?.primary?.main;
      break;
    case 'table':
      // @ts-ignore
      bgcolor = theme.palette?.secondary?.main;
      // @ts-ignore
      color = theme.palette?.primary?.main;
      break;
  }

  return (
    <Tooltip title={record.name}>
      <Avatar
        sx={{ width: 24, height: 24, fontSize: 12, mr: '0.1rem', bgcolor: bgcolor, color: color + ' !important' }}
      >
        {record.name[0].toUpperCase()}
      </Avatar>
    </Tooltip>
  );
}
