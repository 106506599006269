import { FC } from 'react';
import {
  BooleanInput,
  Edit,
  EditProps,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  SelectArrayInput,
  TextInput,
} from 'react-admin';
import { Grid } from '@mui/material';

import SimpleForm from 'components/SimpleForm';

const AppEdit: FC<EditProps> = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <Grid container direction="column" xs={12} lg={6} xl={4}>
        <ImageInput source="icon" label="custom.labels.icon">
          <ImageField source="src" />
        </ImageInput>
        <TextInput source="name" />
        <TextInput source="product_id" />
        <BooleanInput source="lab_enabled" />
        <ReferenceArrayInput source="flavour_ids" reference="flavours">
          <SelectArrayInput source="hardware" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="hardware_ids" reference="hardware">
          <SelectArrayInput source="hardware" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="designation_ids" reference="designations">
          <SelectArrayInput source="name" />
        </ReferenceArrayInput>
      </Grid>
    </SimpleForm>
  </Edit>
);

export default AppEdit;
