import React, { FC } from 'react';
import {
  AutocompleteArrayInput,
  BooleanInput,
  Create,
  EditProps,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import SimpleForm from 'components/SimpleForm';
import { AppOptionRenderer } from '../units/components/UnitEditApps/AppOptionRenderer';

export const CollectionCreate: FC<EditProps> = () => (
  <Create>
    <SimpleForm>
      <ImageInput source="icon">
        <ImageField source="src" />
      </ImageInput>
      <TextInput source="name" />
      <ReferenceInput source="flavour_id" reference="flavours">
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <ReferenceArrayInput source="designation_ids" reference="designations">
        <SelectArrayInput source="name" />
      </ReferenceArrayInput>
      <BooleanInput source="available_as_subscription" />
      <ReferenceArrayInput source="app_ids" reference="apps">
        <AutocompleteArrayInput
          source="name"
          fullWidth
          optionText={<AppOptionRenderer />}
          inputText={(option) => option.translated_name}
          matchSuggestion={(filter, choice) => choice.translated_name.toLowerCase().includes(filter.toLowerCase())}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
